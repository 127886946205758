import React from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Modal,
  Table,
} from "react-bootstrap";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import Route from "routes/Route";
import ReactTable from "components/ReactTable/ReactTable.js";
import { postedSalesInvoiceTableHeader } from "data/dummy";
import { formatDateForReport } from "util/CommonUtil";
import { exportToExcel } from "react-json-to-excel";

const PostedSalesInvoice = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const [postedSalesInvoice, setPostedSalesInvoice] = React.useState([]);
  const [exportData, setExportData] = React.useState([]);
  const [productTypes, setProductTypes] = React.useState([]);
  const [productTypeSelected, setProdutTypeSelected] = React.useState({
    value: "ALL",
    label: "ALL",
  });
  const [storeSelected, setStoreSelected] = React.useState({
    value: "ALL",
    label: "ALL",
  });
  const [regionSelected, setRegionSelected] = React.useState({
    value: "ALL",
    label: "ALL",
  });
  const [extensionSelected, setExtensionSelected] = React.useState({
    value: "ALL",
    label: "ALL",
  });
  const [showRegionSelect, setShowRegionSelect] = React.useState(false);
  const [regions, setRegions] = React.useState([]);
  const [extensions, setExtensions] = React.useState([]);
  const fromDateRef = React.useRef(null);
  const endDateRef = React.useRef(null);
  const [role, setRole] = React.useState(user?.roles[0]?.id);
  const [data, setData] = React.useState({
    category_id: "ALL",
    regional_id:
      role === 8
        ? user?.assign_and_employee?.regional_id
        : "ALL",
    region_extension_id:
      role === 6 || role === 11 ? user?.assign_and_employee?.extension_id : "ALL",
    from_date: "2023-01-01",
    to_date: formatDateForReport(new Date()),
  });
  const storesData = [
    {
      value: "ALL",
      label: "ALL",
    },
    {
      value: "main",
      label: "Main",
    },
    {
      value: "Region",
      label: "Region",
    },
  ];
  const [itemDetail, setItemDetail] = React.useState({});
  const [view, setView] = React.useState(false);
  const viewHandle = (item) => {
    setItemDetail(item);
    setView(true);
  };
  const fetchPostedSalesInvoice = async () => {
    const response = await Route.fetchReports(
      "postedsalesinvoice",
      token,
      data
    );
    if (response?.status === 200) {
      setExportData(
        response?.data?.sales?.flatMap((item) => {
          return item?.details?.map((element) => ({
            invoice_no: item?.invoice_no,
            invoice_date: item?.invoice_date,
            customer_name: item?.customer_name,
            contact_no: item?.customer_contact_no,
            receipt_no: item?.receipt_no,
            payment_mode: item?.payment_mode,
            online_amount: parseFloat(item?.online_amount),
            cash_amount: parseFloat(item?.cash_amount),
            bank_name: item?.bank_name,
            reference_no: item?.reference_no,
            paid_at: item?.paid_date,
            serial_numbers: element?.serialNumbers,
            price: parseFloat(element?.price),
            quantity: element?.quantity,
            net_payable: parseFloat(element?.net_payable),
            total_net_payable: parseFloat(item?.total_net_payable),
            total_gross_payable: parseFloat(item?.total_gross_payable),
            description: element?.description,
            status: element?.status,
          }));
        })
      );   
      setPostedSalesInvoice(
        response?.data?.sales?.map((item) => {
          return {
            invoice_no: item?.invoice_no,
            invoice_date: item?.invoice_date,
            customerName: item?.customer_name,
            contactNo: item?.customer_contact_no,
            receiptNo: item?.receipt_no,
            payment_mode: item?.payment_mode,
            online_amount: item?.online_amount,
            cash_amount: item?.cash_amount,
            bankName: item?.bank_name,
            referenceNo: item?.reference_no,
            paidAt: item?.paid_date,
            action: (
              <div className="actions-right">
                <Button
                  variant="primary"
                  size="sm"
                  className="text-primary btn-link"
                  onClick={() => viewHandle(item)}
                >
                  <i className="fa fa-eye"></i>
                </Button>
              </div>
            ),
          };
        })
      );
    }
  };
  const fetchProductTypes = async () => {
    const response = await Route.fetch("sale-types", token);
    if (response?.status === 200) {
      setProductTypes(response?.data?.saleType);
    }
  };
  const storeHandle = (e) => {
    setStoreSelected((prev) => ({
      ...prev,
      value: e.value,
      label: e.label,
    }));
    if (e.value === "ALL") {
      setShowRegionSelect(false);
      setData((prev) => ({
        ...prev,
        regional_id: e.value,
        region_extension_id: e.value,
      }));
    } else if (e.value === "main") {
      setShowRegionSelect(false);
      setData((prev) => ({
        ...prev,
        regional_id: "",
        region_extension_id: "",
      }));
    } else {
      setShowRegionSelect(true);
    }
  };
  const fetchRegions = async () => {
    const response = await Route.fetch("regions", token);
    if (response?.status === 200) {
      setRegions(response?.data?.region);
    }
  };
  React.useEffect(() => {
    fetchPostedSalesInvoice();
    fetchProductTypes();
    fetchRegions();
  }, []);
  const productTypeHandle = (e) => {
    setProdutTypeSelected({
      value: e.value,
      label: e.label,
    });
    setData((prev) => ({
      ...prev,
      category_id: e.value,
    }));
  };
  const regionHandle = (e) => {
    setRegionSelected((prev) => ({
      ...prev,
      value: e.value,
      label: e.label,
    }));
    setData((prev) => ({
      ...prev,
      regional_id: e.value,
    }));
  };
  const fetchExtensionBasedOnRegion = async () => {
    const response = await Route.fetchDetails(
      "edit-regions",
      token,
      data?.regional_id
    );
    if (response.status === 200) {
      setExtensions(response?.data?.Region?.extensions);
    }
  };
  React.useEffect(() => {
    if (data?.regional_id !== "" && data?.regional_id !== "ALL") {
      fetchExtensionBasedOnRegion();
    }
  }, [data?.regional_id]);
  const extensionHandle = (e) => {
    setExtensionSelected((prev) => ({
      ...prev,
      value: e.value,
      label: e.label,
    }));
    setData((prev) => ({
      ...prev,
      regional_id: "ALL",
      region_extension_id: e.value,
    }));
  };
  const startDateHandle = (e) => {
    setData((prev) => ({
      ...prev,
      from_date: formatDateForReport(e._d),
    }));
  };
  const endDateHandle = (e) => {
    setData((prev) => ({
      ...prev,
      to_date: formatDateForReport(e._d),
    }));
  };
  const clearHandle = () => {
    setProdutTypeSelected((prev) => ({
      ...prev,
      value: "ALL",
      label: "ALL",
    }));
    setStoreSelected((prev) => ({
      ...prev,
      value: "ALL",
      label: "ALL",
    }));
    setShowRegionSelect(false);
    setRegionSelected((prev) => ({
      ...prev,
      value: "ALL",
      label: "ALL",
    }));
    setExtensionSelected((prev) => ({
      ...prev,
      value: "ALL",
      label: "ALL",
    }));
    if (fromDateRef.current) {
      fromDateRef.current.state.selectedDate = null;
      fromDateRef.current.setState({ inputValue: "" });
    }
    if (endDateRef.current) {
      endDateRef.current.state.selectedDate = null;
      endDateRef.current.setState({ inputValue: "" });
    }
    setData((prev) => ({
      ...prev,
      category_id: "ALL",
      regional_id: "ALL",
      region_extension_id: "ALL",
      from_date: "2023-01-01",
      to_date: formatDateForReport(new Date()),
    }));
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Body>
                <Card.Title as="h4">Posted Sales Invoice</Card.Title>
                <Form>
                  <Row>
                    <Col md="2">
                      <Form.Group>
                        <label>Product Type</label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          name="productTypeSelect"
                          value={productTypeSelected}
                          onChange={(e) => productTypeHandle(e)}
                          options={productTypes?.map((item) => ({
                            value: item?.id,
                            label: item?.name,
                          }))}
                          placeholder="Product Type"
                        />
                      </Form.Group>
                    </Col>
                    {role !== 6 && role !== 8 && role !== 11 && (
                      <Col md="2">
                        <Form.Group>
                          <label>Stores</label>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="storeSelect"
                            value={storeSelected}
                            onChange={(e) => storeHandle(e)}
                            options={storesData?.map((item) => ({
                              value: item?.value,
                              label: item?.label,
                            }))}
                            placeholder="Store"
                          />
                        </Form.Group>
                      </Col>
                    )}

                    {showRegionSelect && (
                      <>
                        <Col md="2">
                          <Form.Group>
                            <label>Region</label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              name="regionSelect"
                              value={regionSelected}
                              onChange={(e) => regionHandle(e)}
                              options={regions?.map((item) => ({
                                value: item?.id,
                                label: item?.name,
                              }))}
                              placeholder="Region"
                            />
                          </Form.Group>
                        </Col>
                        <Col md="2">
                          <Form.Group>
                            <label>Extension</label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              name="extensionSelect" // Changed name to extensionSelect
                              value={extensionSelected}
                              onChange={(e) => extensionHandle(e)}
                              options={extensions?.map((item) => ({
                                value: item?.id,
                                label: item?.name,
                              }))}
                              placeholder="Extension"
                            />
                          </Form.Group>
                        </Col>
                      </>
                    )}

                    <Col md="2">
                      <Form.Group>
                        <label>From Date</label>
                        <ReactDatetime
                          inputProps={{
                            className: "form-control",
                            placeholder: "Select From Date",
                          }}
                          closeOnSelect
                          timeFormat={false}
                          ref={fromDateRef}
                          onChange={startDateHandle}
                        ></ReactDatetime>
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <Form.Group>
                        <label>To Date</label>
                        <ReactDatetime
                          inputProps={{
                            className: "form-control",
                            placeholder: "Select To Date",
                          }}
                          closeOnSelect
                          timeFormat={false}
                          ref={endDateRef}
                          onChange={endDateHandle}
                        ></ReactDatetime>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Col md="2" style={{ marginTop: "28px" }}>
                      <Button
                        className="btn-fill"
                        type="button"
                        variant="primary"
                        onClick={fetchPostedSalesInvoice}
                        block
                      >
                        Search
                      </Button>
                    </Col>
                    <Col md="2" style={{ marginTop: "28px" }}>
                      <Button
                        className="btn-outline"
                        type="button"
                        variant="info"
                        onClick={clearHandle}
                        block
                      >
                        Clear
                      </Button>
                    </Col>
                    <Col md="2" style={{ marginTop: "28px" }}>
                      <Button
                        className="btn-fill"
                        type="button"
                        variant="success"
                        onClick={() =>
                          exportToExcel(exportData, "Posted Sales Invoice")
                        }
                        block
                      >
                        Download
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {postedSalesInvoice?.length > 0 ? (
                        <ReactTable
                          data={postedSalesInvoice}
                          columns={postedSalesInvoiceTableHeader}
                          className="-striped -highlight primary-pagination"
                        />
                      ) : (
                        <Alert variant="primary">
                          <span>No Information to Display</span>
                        </Alert>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {view && (
        <Modal show={view} onHide={() => setView(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Posted Sales Invoice Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md="3">
                <label>Customer Name</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Customer Name"
                    type="text"
                    value={itemDetail?.customer_name}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="2">
                <label>Customer No</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Customer No"
                    type="text"
                    value={itemDetail?.customer_contact_no}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="3">
                <label>Invoice No.</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Invoice No"
                    type="text"
                    value={itemDetail?.invoice_no}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="4">
                <label>Receipt No.</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Receipt No"
                    type="text"
                    value={itemDetail?.receipt_no}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <label>Payment Mode</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Payment Mode"
                    type="text"
                    value={itemDetail?.payment_mode}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="3">
                <label>Online Amount</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Online Amount"
                    type="text"
                    value={itemDetail?.online_amount}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="3">
                <label>Cash Amount</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Cash Amount"
                    type="text"
                    value={itemDetail?.cash_amount}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="3">
                <label>Bank Name</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Bank Name"
                    type="text"
                    value={itemDetail?.bank_name}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <label>Reference No.</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Reference No."
                    type="text"
                    value={itemDetail?.reference_no}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="3">
                <label>Paid Date</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Paid Date"
                    type="text"
                    value={itemDetail?.paid_date}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="3">
                <label>Net Amount</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Net Amount"
                    type="text"
                    value={itemDetail?.total_net_payable}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="3">
                <label>Gross Amount</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Gross Amount"
                    type="text"
                    value={itemDetail?.total_gross_payable}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Serial Number</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Net Pay</th>
                      <th>Status</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemDetail?.details?.map((item, index) => (
                      <tr key={index}>
                        <th>{index + 1}</th>
                        <th>{item?.serialNumbers}</th>
                        <th>{item?.price}</th>
                        <th>{item?.quantity}</th>
                        <th>{item?.net_payable}</th>
                        <th>{item?.status}</th>
                        <th>{item?.description}</th>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row
              style={{ display: "flex", justifyContent: "flex-end" }}
              className="my-4"
            >
              <Col md="3">
                <Button
                  variant="danger"
                  className="btn-outline"
                  onClick={() => setView(false)}
                  block
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default PostedSalesInvoice;
